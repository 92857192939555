<template>
  <div>
    <div class="row mt-3">
      <div class="col-sm-12 viatico-titulo">Comentarios</div>
    </div>

    <template v-if="comentarios_paginados.length > 0">
    <div v-for="comentario in comentarios_paginados" :key="comentario.id" class="comentario">
      <div class="data">{{ comentario.comentario }}</div>
      <div class="info">
        <div class="nombre">{{ comentario.nombre }}</div>
        <div class="fecha">{{ (comentario.num_item_pag ? '#'+comentario.num_item_pag : '')+' - '+$moment(comentario.created_at).format('ddd DD MMM YYYY HH:mm') }}</div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-5 offset-sm-7 text-right paginacion">
        <div class="anterior" @click="primera_pagina"><i class="fa-solid fa-angles-left"></i></div>
        <div class="anterior" @click="pagina_anterior"><i class="fa-solid fa-chevron-left"></i></div>
        <div v-for="pagina in $paginator.paginate_navigation(comentarios_filtrados, pagina_actual,por_pagina, 3)" :key="pagina" :class="(pagina_actual == pagina) ? 'pagina activo' : 'pagina'" @click="pagina_actual=pagina">{{ pagina }}</div>
        <div class="siguiente" @click="pagina_siguiente"><i class="fa-solid fa-chevron-right"></i></div>
        <div class="siguiente" @click="ultima_pag"><i class="fa-solid fa-angles-right"></i></div>

      </div>
    </div>
    </template>
    <div v-else class="sin_comentarios">Sin comentarios</div>
    
    <form  v-if="!estatus_validos(viatico.estatus,['ESTATUS_CERRADO'])" @submit.prevent="enviar_comentario">
      <div class="input-group mb-3">
        <input v-model="comentario" type="text" class="form-control" placeholder="Comentario nuevo..." aria-label="Comentario nuevo...">
        <div class="input-group-append">
          <button class="btn btn-primary">Enviar</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import config from '@/apps/tesoreria/configuracion/viaticos'

import api from '@/apps/constelacion/api/tesoreria';

export default {
  props: {
    viatico: {
      type: Object,
      default() {
        return {
          comentarios: []
        }
      }
    }
  }
  ,data() {
    return  {
      comentario: null
      ,pagina_actual: 1
      ,por_pagina: 3
    }
  }
  ,methods: {
    async enviar_comentario() {
      try {
        if (!this.comentario || this.comentario.length < 4)
          return this.$helper.showMessage('Error','Debes definir un comentario','eror','alert');

        let res = (await api.agregar_comentario(this.viatico.id, {comentario: this.comentario})).data;
        this.$log.info('res', res);

        this.comentario = null;
        this.$emit('update');
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
    ,pagina_anterior() {
      if (this.pagina_actual > 1)
        this.pagina_actual -= 1;
    }
    ,primera_pagina() {
      this.pagina_actual = 1;
    }
    ,pagina_siguiente() {
      // this.$log.info('pagina_siguiente',this.pagina_siguiente)
      if (this.pagina_actual < this.$paginator.total_pages(this.viatico.comentarios, this.por_pagina))
        this.pagina_actual += 1;
    }
    ,ultima_pag() {
      this.pagina_actual = this.$paginator.total_pages(this.viatico.comentarios, this.por_pagina)-1;
    }
    ,estatus_validos(valor, estatus) {
      return config.viaticos.estatus_validos(valor, estatus);
    }
  }
  ,computed: {
    comentarios_filtrados() {
      let comentarios = [];

      for(let i=0; i<this.viatico.comentarios.length; i++) {
        if (this.viatico.comentarios[i].tipo == 1)
          comentarios.push(this.viatico.comentarios[i]);
      }

      return comentarios;
    },
    comentarios_paginados() {
      return this.$paginator.paginate_data(this.comentarios_filtrados,this.pagina_actual,this.por_pagina, 'desc');
    }
  }
}
</script>

<style lang="scss" scoped>
  .comentario {
    font-size: 0.9em;
    border: solid 1px #e0e0e0;
    border-radius: 3px;
    margin-bottom: 10px;

    .data {
      padding: 5px 10px;
    }

    .info {
      background-color: #e0e0e0;
      color: #666666;
      font-size: 0.8em;
      padding: 3px 7px;
      overflow: auto;

      .nombre {
        float: left;
      }

      .fecha {
        float: right;
      }
    }
  }

  .sin_comentarios {
    border: solid 1px #e0e0e0;
    border-radius: 3px;
    padding: 5px 10px;
    margin-bottom: 10px;
  }

  .paginacion {
    margin-bottom: 10px;

    .anterior, .siguiente, .pagina {
      color: #979797;
      display: inline-block;
      padding: 3px 8px;
      cursor: pointer;
    }

    .anterior, .siguiente {
      // min-width: 50px;
      border: solid 1px #e0e0e0;
      border-radius: 3px;

      i {
        font-size: 0.7em;
      }
    }

    .anterior:hover, .siguiente:hover, .pagina:hover {
      color: #493cbe;
    }

    .anterior {
      border-right: 0px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    .siguiente {
      border-left: 0px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    .pagina {
      border: solid 1px #e0e0e0;
    }

    .activo {
      background-color: #6159aa;
      color: #fff;
    }
  }
</style>