<template>
  <div>
    <Modal :options="{close: true}" @close="$emit('close')">
      <form @submit.prevent="crear_viatico">
        <div class="title">Nuevo viatico</div>
        <div class="body">
          
          <div class="row form-group">
            <label for="autorizador" class="col-form-label col-sm-3">Área</label>
            <div class="col-sm-9">
              <select v-model="viatico.autorizador_id" name="autorizador" id="autorizador" class="form-control">
                <option value="na">Seleccionar área a la que perteneces</option>
                <option v-for="autorizador in autorizadores" :key="autorizador.id" :value="autorizador.id">{{ autorizador.area }} ( {{ autorizador.nombre }})</option>
              </select>
            </div>
          </div>
          <div class="row form-group">
            <label for="identificador" class="col-form-label col-sm-3">Indentificador</label>
            <div class="col-sm-9">
              <input v-model="viatico.identificador" type="text" name="identificador" id="identificador" class="form-control">
              <small class="descript">Texto breve para identificar a simple vista a que se refiere el viatico.</small>
            </div>
          </div>
          <div class="row form-group">
            <label for="forma_pago" class="col-form-label col-sm-3">Forma de pago</label>
            <div class="col-sm-9">
              <div class="input-group mb-3">
                <select v-model="viatico.forma_pago" name="forma_pago" id="forma_pago" class="form-control">
                  <option value="na">Selecciona forma de pago</option>
                  <option v-for="fp in formas_pago" :key="fp.id" :value="fp.id">{{ fp.nombre }}</option>
                </select>
                <div class="input-group-append">
                  <button class="btn btn-info" type="button" @click="modal_forma_pago=true">Agregar forma de pago</button>
                </div>
              </div>
            </div>
          </div>
          <div class="row form-group">
            <label for="descripcion" class="col-form-label col-sm-3">Descripción de solicitud </label>
            <div class="col-sm-9">
              <textarea v-model="viatico.descripcion" name="descripcion" id="descripcion" cols="30" rows="10" class="form-control"></textarea>
              <small class="descript">Intenta ser descriptivo el uso que le darás al/los viaticos.</small>
            </div>
          </div>
          <div class="row form-group">
            <label for="" class="col-form-label col-sm-3">Monto</label>
            <div class="col-sm-9">
              <IC v-model="viatico.monto" id="monto" name="monto" label="$" :formats="['moneyFormat']" /> 
              <small class="descript">Monto total del viatico a solicitar.</small>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-12 text-right">
              <button class="btn btn-primary mr-2">Guardar</button>
              <button class="btn btn-danger" type="button" @click="$emit('close')">Cancelar</button>
            </div>
          </div>
        </div>
      </form>
    </Modal>

    <FormaPago v-if="modal_forma_pago" @update="obtener_formas_pagos" @close="modal_forma_pago=false" />
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal';
  import IC from '@/components/Form/InputCompuesto';

  import FormaPago from './Form/FormaPago'

  import api from '../../api/tesoreria';

  export default {
    components: {
      Modal, IC, FormaPago
    }
    ,data() {
      return {
        viatico: {
          autorizador_id: 'na',
          identificador: null,
          forma_pago: 'na',
          descripcion: null,
          monto: 0
        }
        ,formas_pago: []
        ,autorizadores: []
        ,modal_forma_pago: false
      }
    }
    ,mounted() {
      this.cargar_autorizadores();
      this.obtener_formas_pagos();
    }
    ,methods: {
      async cargar_autorizadores() {
        try {
          this.autorizadores = (await api.autorizadores()).data;
        }catch(e) {
          this.$log.info('error', e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,async obtener_formas_pagos(tipo) {
        try {
          this.formas_pago = (await api.obtener_formas_pagos()).data;

          if (tipo)
            this.viatico.forma_pago = tipo.id;

        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,async crear_viatico() {
        try {
          let isValid = await this.$validator({
            autorizador: 'required',
            identificador: 'required|min:5',
            forma_pago: 'required',
            descripcion: 'required|min:10',
            monto: 'required|money'
          })

          if (!isValid)
            throw new Error('Se encontraron errores en el formulario');

          if(process.env.VUE_APP_ENV != 'production' && this.viatico.monto > 10)
            throw new Error('no puedes usar montos mayores a 10 pesos en las pruebas');
          
          let res = (await api.crear_viatico(this.viatico)).data;
          this.$log.info('res', res);

          this.$emit('update', res);
        }catch(e) {
          this.$log.info('error', e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .descript {
    color: #666666;
  }

  #descripcion {
    resize: none;
  }
</style>