<template>
  <div>
    <Modal :options="{close: true}" @close="$emit('close')">
      <div class="title">Detalle: {{ viatico.identificador }}</div>
      <div class="body">
          <div class="row">
            <div class="col-sm-12 viatico">
              <div class="row">
                <div class="col-sm-12 viatico-titulo">Información</div>
              </div>
              <div class="row form-group viatico-campo">
                <div class="col-sm-3"><span class="subtitulo">Área:</span></div>
                <div class="col-sm-9"><span class="info-viatico">{{ viatico.autorizador.area }}</span></div>
              </div>

              <div class="row form-group viatico-campo">
                <div class="col-sm-3"><span class="subtitulo">Identificador:</span></div>
                <div class="col-sm-9"><span class="info-viatico">{{ viatico.identificador }}</span></div>
              </div>

              <div class="row form-group viatico-campo">
                <div class="col-sm-3"><span class="subtitulo">Descripción:</span></div>
                <div class="col-sm-9"><span class="info-viatico">{{ viatico.descripcion }}</span></div>
              </div>

              <div class="row form-group viatico-campo">
                <div class="col-sm-3"><span class="subtitulo">Monto:</span></div>
                <div class="col-sm-9"><span class="info-viatico">${{ $helper.moneyFormat(viatico.monto) }}</span></div>
              </div>

            <div class="row form-group viatico-campo">
              <div class="col-sm-3"><span class="subtitulo">Monto comprobantes:</span></div>
              <div class="col-sm-9"><span class="info-viatico">${{ $helper.moneyFormat(monto_comprobantes) }}</span></div>
            </div>

            <div v-if="viatico.tipo_cobro_sobrante" class="row form-group viatico-campo">
              <div class="col-sm-3"><span class="subtitulo">Tipo de cobro:</span></div>
              <div class="col-sm-9"><span class="info-viatico">{{ viatico.tipo_cobro_sobrante == 1 ? 'Deposito' : 'Descuento por nómina' }}</span></div>
            </div>

            <div class="row viatico-campo">
              <div class="col-sm-3"><span class="subtitulo">Estatus:</span></div>
              <div class="col-sm-9"><span class="info-viatico">{{ mapear_estatus(viatico.estatus) }}</span></div>
            </div>

            <Evidencia v-if="estatus_validos(viatico.estatus,['ESTATUS_COBRO_MONTO_SOBRANTE','ESTATUS_VALIDANDO_COBRO_MONTO_SOBRANTE', 'ESTATUS_RECHAZO_COBRO_MONTO_SOBRANTE', 'ESTATUS_CERRADO'])" :viatico="viatico" @update="obtener_viatico" />
            <Comprobantes v-if="estatus_validos(viatico.estatus, ['ESTATUS_CONCILIACION','ESTATUS_VALIDANDO_COMPROBANTES','ESTATUS_PAGO_MONTO_EXTRA','ESTATUS_COBRO_MONTO_SOBRANTE','ESTATUS_CERRADO','ESTATUS_CONCILIACION_ICONSISTENCIA'])" :viatico="viatico" @update="obtener_viatico" />
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <Comentario :viatico="viatico" @update="obtener_viatico" />
          </div>
        </div>
        
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button v-if="estatus_validos(viatico.estatus, ['ESTATUS_PAGADA'])" class="btn btn-primary mr-2" @click="modal_conciliacion=true">Iniciar conciliación</button>
            <button v-if="estatus_validos(viatico.estatus, ['ESTATUS_CONCILIACION'])" class="btn btn-success mr-2" @click="finalizar_conciliacion">Finalizar conciliación</button>
            <button v-if="estatus_validos(viatico.estatus, ['ESTATUS_COBRO_MONTO_SOBRANTE', 'ESTATUS_RECHAZO_COBRO_MONTO_SOBRANTE'])" class="btn btn-success mr-2" @click="finalizar_carga_evidencia">Finalizar carga evidencia</button>
          </div>
        </div>
      </div>
    </Modal>

    <Conciliacion v-if="modal_conciliacion" @aceptar="aceptar_conciliacion" @cancelar="modal_conciliacion=false" />
  </div>
</template>

<script>
  import Modal from '@/components/Modal';

  import Comentario from './Info/Comentario'
  import Documento from './Info/Documento'
  import Comprobantes from './Info/Comprobante'
  import Conciliacion from './Info/Conciliacion'
  import Evidencia from './Info/Evidencia'

  import config from '@/apps/tesoreria/configuracion/viaticos'

  import api from '@/apps/constelacion/api/tesoreria';

  export default {
    components: {
      Modal, Comentario, Documento, Comprobantes, Conciliacion, Evidencia
    },
    props: {
      viatico_id: {
        type: Number,
        require: true
      }
      ,catalogo: {
        type: Array,
        default() {
          return []
        }
      }
    }
    ,data() {
      return {
        viatico: {
          id: null,
          autorizador_id: null,
          identificador: null,
          descripcion: null,
          monto: 0,
          estatus: 1,
          comentarios: [],
          documentos: [],
          autorizador: {
            area: null
          },
          conceptos: [],
          comprobantes: []
        }
        ,modal_conciliacion: false
      }
    }
    ,mounted() {
      this.obtener_viatico();
    }
    ,methods: {
      async obtener_viatico() {
        try {
          this.viatico = (await api.obtener_viatico(this.viatico_id)).data;
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,mapear_estatus(estatus) {
        return this.catalogo.find(cat => cat.valor == estatus).nombre;
      }
      ,async actualizar_estatus(estatus) {
        try {
          let viatico = this.$helper.clone(this.viatico);
          viatico.estatus = estatus;
          let res = (await api.editar_viatico(this.viatico.id, viatico)).data;
          this.$log.info('res', res);
          this.obtener_viatico();
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,aceptar_conciliacion() {
        this.actualizar_estatus(this.catalogo_estatus.ESTATUS_CONCILIACION);
        this.modal_conciliacion = false;
      }
      ,estatus_validos(valor, estatus) {
        return config.viaticos.estatus_validos(valor, estatus);
      }
      ,finalizar_conciliacion() {
        if (this.monto_comprobantes == 0)
          return this.$helper.showMessage('Error','Antes de finalizar concilación debes agregar al menos un componente','error','alert');

        this.actualizar_estatus(this.catalogo_estatus.ESTATUS_VALIDANDO_COMPROBANTES);
      }
      ,finalizar_carga_evidencia() {
        if (this.viatico.evidencias.length == 0)
          return this.$helper.showMessage('Error','Debes agregar al menos una evidencia','error','alert');

        this.actualizar_estatus(this.catalogo_estatus.ESTATUS_VALIDANDO_COBRO_MONTO_SOBRANTE);
      }
    }
    ,computed: {
      monto_comprobantes() {
        let monto = 0;

        if (this.viatico && this.viatico.comprobantes)
          this.viatico.comprobantes.forEach(comprobante => {
            if ([1,2].indexOf(comprobante.estatus) != -1)
              monto += parseFloat(comprobante.monto);
          })

        return monto;
      },
      en_conciliacion() {
        return this.estatus_validos(this.viatico.estatus, [
          'ESTATUS_CONCILIACION',
          'ESTATUS_VALIDANDO_COMPROBANTES',
          'ESTATUS_CONCILIACION_ICONSISTENCIA',
        ]);
      }
      ,viatico_pagado() {
        let estatus = this.catalogo.find(opcion => opcion.codigo == 'pagada');

        return estatus.valor == this.viatico.estatus;
      }
      ,catalogo_estatus() {
        return config.viaticos.constantes;
      }
    }
  }
</script>

<style lang="scss">

.bock-info {
  max-height: calc(60vh - 300px);
  overflow: hidden;
  overflow-y: auto;
}

.viatico-titulo {
  font-size: 1.75rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.viatico {
  .viatico-campo {
    border-bottom: solid 1px #d4d4d4;

    .subtitulo {
      width: 100%;
      padding: 5px 10px;
      display: inline-block;
    }
  }

  .viatico-campo:first-child {
    border-bottom: 10px !important;
  }
}
</style>