<template>
  <div>
    <Modal :options="{width: '40vw', close: true}" @close="$emit('close')">
      <div class="title">Agregar forma de pago</div>
      <form @submit.prevent="agragar_forma_pago">
        <div class="body">
          <div class="row form-group">
            <label for="nombre" class="col-form-label col-sm-4">Nombre de la forma de pago</label>
            <div class="col-sm-8"><input v-model="forma_pago.nombre" type="text" name="nombre" id="nombre" class="form-control"></div>
          </div>
          <div class="row form-group">
            <label for="cuenta" class="col-form-label col-sm-4">Número de cuenta</label>
            <div class="col-sm-8"><input v-model="forma_pago.cuenta_beneficiario" type="text" name="cuenta" id="cuenta" class="form-control"></div>
          </div>
          <div class="row form-group">
            <label for="nombre_beneficiario" class="col-form-label col-sm-4">Nombre del beneficiario</label>
            <div class="col-sm-8"><input v-model="forma_pago.nombre_beneficiario" type="text" name="nombre_beneficiario" id="nombre_beneficiario" class="form-control"></div>
          </div>
          <div class="row form-group">
            <label for="banco" class="col-form-label col-sm-4">Banco</label>
            <div class="col-sm-8">
              <select v-model="forma_pago.institucion_operante" name="banco" id="banco" class="form-control">
                <option value="na">Selecciona un banco</option>
                <option v-for="banco in bancos" :key="banco.id" :value="banco.valor">{{ banco.nombre }}</option>
              </select>
            </div>
          </div>
          <div class="row form-group">
            <label for="tipo_cuenta" class="col-form-label col-sm-4">Tipo de cuenta</label>
            <div class="col-sm-8">
              <select v-model="forma_pago.tipo_cuenta_beneficiario" name="tipo_cuenta" id="tipo_cuenta" class="form-control">
                <option value="na">Selecciona un banco</option>
                <option v-for="tipoCuenta in tipos_cuentas" :key="tipoCuenta.id" :value="tipoCuenta.valor">{{ tipoCuenta.nombre }}</option>
              </select>
            </div>
          </div>
          <div class="row form-group">
            <label for="rfc" class="col-form-label col-sm-4">RFC</label>
            <div class="col-sm-8"><input v-model="forma_pago.rfc_curp_beneficiario" type="text" name="rfc" id="rfc" class="form-control"></div>
          </div>
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-12 text-right">
              <button class="btn btn-primary mr-2">Agregar</button>
              <button class="btn btn-danger" type="button" @click="$emit('close')">Cancelar</button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';

import api from '@/apps/constelacion/api/tesoreria'

export default {
  components: {
    Modal,
  },
  data() {
    return {
      bancos: []
      ,tipos_cuentas: []
      ,forma_pago: {
        nombre: null
        ,cuenta_beneficiario: null
        ,nombre_beneficiario: null
        ,institucion_operante: 'na'
        ,tipo_cuenta_beneficiario: 'na'
        ,rfc_curp_beneficiario: null
      }
    }
  }
  ,mounted() {
    this.obtener_catalogos();
  }
  ,methods: {
    async obtener_catalogos() {
      try {
        this.bancos = (await api.buscar_catalogo('bancos')).data;
        this.tipos_cuentas = (await api.buscar_catalogo('tipos_cuentas')).data;
      }catch(e) {
        this.$log.info('error', e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
    ,async agragar_forma_pago() {
      try {
        let isValid = await this.$validator({
          nombre: 'required'
          ,cuenta: 'required|numeric'
          ,nombre_beneficiario: 'required'
          ,banco: 'required'
          ,tipo_cuenta: 'required'
          ,rfc: 'required'
        })

        if (!isValid)
          throw new Error('Se encontraror errores en el formulario');

        let res = (await api.crear_forma_pago(this.forma_pago)).data;
        this.$log.info('res', res);

        this.$emit('update', res);
        this.$emit('close');
      }catch(e) {
        this.$log.info('error', e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
  }
};
</script>