<template>
  <div>
    <div class="row my-3">
      <h1 class="col-sm-12 text-right">Mis viaticos</h1>
    </div>

    <DataGrid :config="dataGrid_config" :data="viaticos" :select="seleccionar" @actions="dataGridActions" @ready="buscar_botones">
      <div class="row">
        <div class="col-sm-12">
          <button class="btn btn-warning mr-2" @click="cargar_viaticos">Recargar</button>
          <button class="btn btn-primary" @click="modal_form=true;">Solicitar viatico </button>
        </div>
      </div>
    </DataGrid>

    <FormViatico v-if="modal_form" @update="actualizar_registros" @close="modal_form=false" />
    <Info v-if="modal_info" :viatico_id="viatico.id" :catalogo="catalogo_estatus" @close="modal_info=false"/>
  </div>
</template>

<script type="text/javascript">
  import DataGrid from '@/components/DataGridV2';

  import FormViatico from './Viatico/Form';
  import Info from './Viatico/Info';

  import api from '../api/tesoreria';

  export default {
    components: {
      DataGrid, FormViatico, Info
    },
    data() {
      return {
        dataGrid_config: {
          name: 'viaticos',
          select_items: false,
          cols: {
            id: 'ID',
            identificador: 'Identificador',
            monto: 'Monto',
            estatus: 'Estatus',
            created_at: 'Fecha',
            opciones: 'Opciones'
          },
          paginator: {
            pagina_actual: 1
            ,total_registros: 1
            ,registros_por_pagina: 20
          },
          mutators: {
            monto(val, row, vue) {
              return '$'+vue.$helper.moneyFormat(val);
            },
            created_at(val,row,vue) {
              return vue.$moment(val).format('YYYY-MM-DD hh:mm');
            },
            opciones(val, row) {
              return '<button class="btn btn-primary viatico-btn" data-id="'+row.id+'">Revisar</button>';
            }
          }
        }
        ,options: {
          page: 1
          ,order_col: 'id'
          ,order_dir: 'desc'
          ,limit: 20
          ,filters: []
        }
        ,viaticos: []
        ,viatico: null
        ,autorizadores: []
        ,seleccionar: false
        ,seleccionadas: []
        ,modal_form: false
        ,modal_info: false
        ,catalogo_estatus: []
      }
    }
    ,mounted() {
      this.$log.info('auth: ', this.$auth.getAuth());
    }
    ,methods: {
      dataGridActions(tipo, data) {
        if (tipo == 'options') {
          this.options = data;
          this.cargar_viaticos();
        }else {
          this.seleccionadas = data;
          this.seleccionar = true;
        }
      }
      ,buscar_botones() {
        let btns = document.querySelectorAll('.viatico-btn');

        btns.forEach(btn => {
          btn.removeEventListener('click', this.revisar_viatico);
          btn.addEventListener('click', this.revisar_viatico);
        });
      }
      ,async obtener_catalogo_estatus() {
        try {
          this.catalogo_estatus = (await api.buscar_catalogo('estatus_viaticos')).data;
          this.$log.info('obtenemos catalogo estatus');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,async cargar_viaticos() {
        try {

          await this.obtener_catalogo_estatus();

          let res = (await api.obtener_viaticos(this.options)).data;

          for(let i=0; i<res.data.length; i++) {
            res.data[i].estatus = this.catalogo_estatus.find(op => op.valor == res.data[i].estatus).nombre;
          }

          this.viaticos = res.data;
          this.dataGrid_config.paginator.pagina_actual = res.current_page;
          this.dataGrid_config.paginator.total_registros = res.total;
          this.dataGrid_config.paginator.registros_por_pagina = parseInt(res.per_page);
        }catch(e) {
          this.$log.info('error', e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,actualizar_registros() {
        this.cargar_viaticos();
        this.modal_form = false;
      }
      ,revisar_viatico(e) {
        let id = e.target.dataset.id;
        this.$log.info(e.target);

        this.viaticos.forEach(viatico => {
          if (viatico.id == id)
            this.viatico = viatico;
        })

        if (!this.viatico)
          return this.$helper.showMessage('Error','No se encontró el viatico','error','alert');

        this.modal_info = true;
      }
    }
  }
</script>